import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27')
];

export const server_loads = [2];

export const dictionary = {
		"/(no-auth)": [21],
		"/(auth)/account": [11,[2]],
		"/(no-auth)/account/reset-password": [22],
		"/(no-auth)/account/reset-password/confirm": [23],
		"/(no-auth)/account/sign-in": [~24],
		"/(no-auth)/account/sign-up": [25],
		"/(no-auth)/account/verify-email": [26],
		"/(auth)/(admin)/admin/cloud/api-docs": [6,[2,3]],
		"/(auth)/(admin)/admin/cloud/home": [7,[2,3]],
		"/(auth)/(admin)/admin/cloud/tasks": [8,[2,3]],
		"/(auth)/(admin)/admin/organizations": [9,[2,3]],
		"/(auth)/(admin)/admin/organizations/[id]": [10,[2,3]],
		"/(auth)/brands/[brandId]": [~12,[2,4]],
		"/(auth)/brands/[brandId]/brand": [13,[2,4]],
		"/(auth)/brands/[brandId]/content": [14,[2,4]],
		"/(auth)/brands/[brandId]/following": [15,[2,4]],
		"/(auth)/brands/[brandId]/posts": [16,[2,4]],
		"/(auth)/brands/[brandId]/posts/[postId]": [17,[2,4]],
		"/(auth)/dashboard": [18,[2]],
		"/(auth)/organization": [19,[2,5]],
		"/(auth)/organization/team": [20,[2,5]],
		"/(no-auth)/sentry/test": [27]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
	init: client_hooks.init,
	reroute: (() => {}),
	transport: {}
};

export const decoders = Object.fromEntries(Object.entries(hooks.transport).map(([k, v]) => [k, v.decode]));

export const hash = false;

export const decode = (type, value) => decoders[type](value);

export { default as root } from '../root.js';